<div>
  <label>{{ title }}
    <button mat-mini-fab (click)="add()">
      <mat-icon>add</mat-icon>
    </button>
  </label>
  <div *ngFor="let item of list; let i = index; trackBy: trackByFn">
      <app-table-edit [table]="item"></app-table-edit>
      <mat-icon matSuffix (click)="remove(i)">remove</mat-icon>
  </div>
</div>
