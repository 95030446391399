<mat-card>
  <h2>Content Groups</h2>
  <mat-expansion-panel *ngFor="let c of addedContent; let i = index">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{c.fileName}}
      </mat-panel-title>
      <mat-panel-description>
        <button mat-icon-button (click)="removeContent(c.fileName, $event)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="downloadContent(i, $event)">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </mat-panel-description>
      <ng-template matExpansionPanelContent>
        <app-content-category-display [title]="'Ancestry'" [data]="c.data.ancestries"
                                      (addEntry)="addNew('ancestries', i)" (editEntry)="edit('ancestries', $event,i)"
                                      (deleteEntry)="removeEntry('ancestries', $event, i)">
        </app-content-category-display>
        <app-content-category-display [title]="'Creature'" [data]="c.data.creatures"
                                      (addEntry)="addNew('creatures', i)" (editEntry)="edit('creatures',$event,i)"
                                      (deleteEntry)="removeEntry('creatures', $event, i)">
        </app-content-category-display>
        <app-content-category-display [title]="'Item'" [data]="c.data.items"
                                      (addEntry)="addNew('items', i)" (editEntry)="edit('items', $event,i)"
                                      (deleteEntry)="removeEntry('items', $event, i)">
        </app-content-category-display>
        <app-content-category-display [title]="'Path'" [data]="c.data.paths"
                                      (addEntry)="addNew('paths', i)" (editEntry)="edit('paths', $event,i)"
                                      (deleteEntry)="removeEntry('paths', $event, i)">
        </app-content-category-display>
        <app-content-category-display [title]="'Relic'" [data]="c.data.relics"
                                      (addEntry)="addNew('relics', i)" (editEntry)="edit('relics', $event,i)"
                                      (deleteEntry)="removeEntry('relics', $event, i)">
        </app-content-category-display>
        <app-content-category-display [title]="'Spell'" [data]="c.data.spells"
                                      (addEntry)="addNew('spells', i)" (editEntry)="edit('spells', $event,i)"
                                      (deleteEntry)="removeEntry('spells', $event, i)">
        </app-content-category-display>
        <app-content-category-display [title]="'Tradition'" [data]="c.data.traditions"
                                      (addEntry)="addNew('traditions', i)" (editEntry)="edit('traditions', $event,i)"
                                      (deleteEntry)="removeEntry('traditions', $event, i)">
        </app-content-category-display>
        <app-content-category-display [title]="'Reference'" [data]="c.data.references"
                                      (addEntry)="addNew('references', i)" (editEntry)="edit('references', $event,i)"
                                      (deleteEntry)="removeEntry('references', $event, i)">
        </app-content-category-display>
        <app-content-category-display [title]="'Vehicle'" [data]="c.data.vehicles"
                                      (addEntry)="addNew('vehicles', i)" (editEntry)="edit('vehicles', $event,i)"
                                      (deleteEntry)="removeEntry('vehicles', $event, i)">
        </app-content-category-display>
      </ng-template>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-card>
<mat-card>
  <input type="file" #file name="File Upload"
         id="txtFileUpload" (change)="onFilesAdded($event) " accept=".json "/>
  <button mat-raised-button (click)="addContentGroup()">Create Content Group</button>
  <button mat-raised-button (click)="addExamples()">Add Examples</button>
  <button mat-raised-button (click)="combine()">Combine</button>
</mat-card>
