<h2 mat-dialog-title>Path : {{path.name}}
  <button mat-button (click)="openPreview()">Preview</button>
</h2>
<mat-dialog-content>
  <mat-card>
    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="path.name" (ngModelChange)="applyId()" placeholder="Name"/>
      </mat-form-field>
      <app-id-edit [value]="path.id" (valueChange)="path.id = $event"></app-id-edit>
      <mat-form-field>
        <mat-label>Tier</mat-label>
        <mat-select [(value)]="path.tier" (selectionChange)="onTierSelect()">
          <mat-option [value]="'Novice'">Novice</mat-option>
          <mat-option [value]="'Expert'">Expert</mat-option>
          <mat-option [value]="'Master'">Master</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex">
      <mat-form-field>
      <input matInput [(ngModel)]="path.shortDescription" placeholder="Short description"/>
      </mat-form-field>
    </div>
    <app-md-input [label]="'Description'" [name]="'description'" [id]="'description'"
                  [(model)]="path.description"></app-md-input>
    <app-md-input [label]="'Extra'" [name]="'extra'" [id]="'extra'" [(model)]="path.extra"></app-md-input>
    <app-path-level-input [(pathLevels)]="path.pathLevels"></app-path-level-input>

    <h3>Story Development</h3>
    <app-table-edit [table]="path.storyDevelopment"></app-table-edit>
    <app-source-input [source]="path.source"></app-source-input>
  </mat-card>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()">Save</button>
  </mat-dialog-actions>

</mat-dialog-content>
