<div class="button-container">
  <button mat-raised-button color="primary" [routerLink]="'/ancestry-list'">Ancestries</button>
  <button mat-raised-button color="primary" [routerLink]="'/creature-list'">Creatures</button>
  <button mat-raised-button color="primary" [routerLink]="'/item-list'">Items</button>
  <button mat-raised-button color="primary" [routerLink]="'/path-list'">Paths</button>
  <button mat-raised-button color="primary" [routerLink]="'/relic-list'">Relics</button>
  <button mat-raised-button color="primary" [routerLink]="'/spell-list'">Spells</button>
  <button mat-raised-button color="primary" [routerLink]="'/vehicle-list'">Vehicles</button>
  <button mat-raised-button color="primary" [routerLink]="'/tradition-list'">Traditions</button>
  <button mat-raised-button color="primary" [routerLink]="'/reference-list/traditiontalents'">Tradition Talents</button>
</div>

<app-footer></app-footer>
