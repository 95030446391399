<h2 mat-dialog-title>Tradition : {{tradition.name}}
  <button mat-button (click)="openPreview()">Preview</button>
</h2>
<mat-dialog-content>
  <mat-card>
    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="tradition.name" (ngModelChange)="applyId()" placeholder="Name"/>
      </mat-form-field>
      <app-id-edit [value]="tradition.id" (valueChange)="tradition.id = $event"></app-id-edit>
    </div>
    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="tradition.attribute" placeholder="Attribute"/>
      </mat-form-field>
    </div>
    <app-md-input [label]="'Description'" [name]="'description'" [id]="'description'"
                  [(model)]="tradition.description"></app-md-input>
    <div class="flex">
      <mat-checkbox [(ngModel)]="tradition.dark">
        Dark
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="tradition.psychic">
        Psychic
      </mat-checkbox>
    </div>
    <app-source-input [source]="tradition.source"></app-source-input>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
