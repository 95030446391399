<mat-card>
  <mat-card-title>
    {{ reference.name }}
  </mat-card-title>
  <mat-card-content style="display: flex; flex-direction: column;">
      <app-processed-text [text]="reference.content"></app-processed-text>
  </mat-card-content>
  <mat-card-footer>
    {{ reference.source.book }} Page {{ reference.source.page }}
  </mat-card-footer>
</mat-card>
