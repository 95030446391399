<span *ngFor="let part of content">
  <ng-container [ngSwitch]="part.type">
    <ng-container *ngSwitchCase="'text'">{{ part.content }}</ng-container>
    <ng-container *ngSwitchCase="'strong'"><b>{{ part.content }}</b></ng-container>
    <ng-container *ngSwitchCase="'em'"><i>{{ part.content }}</i></ng-container>
    <ng-container *ngSwitchCase="'italics'"><i>{{ part.content }}</i></ng-container>
    <ng-container *ngSwitchCase="'link'">
        <app-content-tooltip [link]="part.url" [text]="part.text"></app-content-tooltip>
    </ng-container>
    <ng-container *ngSwitchCase="'softbreak'"><br/></ng-container>
  </ng-container>
</span>
