<div style="display: flex; flex-direction: row;">
  <div class="table-side">
    <app-tradition-filter (filterChange)="applyFilter($event)" [sources]="bookSources"></app-tradition-filter>
    <div style="height: 400px; overflow: auto;">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <span class="icon-wrapper">{{ element.name }}
              <span *ngIf="element.dark">
                <app-tradition-icon [type]="'dark'"></app-tradition-icon>
              </span>
              <span *ngIf="element.psychic">
                <app-tradition-icon [type]="'psychic'"></app-tradition-icon>
              </span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="attribute">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Attribute</th>
          <td mat-cell *matCellDef="let element">{{ element.attribute }}</td>
        </ng-container>

        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
          <td mat-cell *matCellDef="let element">{{ element.source.book }}</td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="columnsToDisplay; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let myRowData; columns: columnsToDisplay"
          (click)="select(myRowData)"
          class="selected"
          [class.selected]="myRowData.id === selected?.id"
        ></tr>
      </table>
    </div>
  </div>
  <div class="details-side" *ngIf="selected">
    <app-tradition-details [tradition]="selected"></app-tradition-details>
  </div>
</div>
