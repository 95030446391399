<ng-container *ngFor="let item of dataList">
  <ng-container [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'heading'"><p [ngClass]="'mat-h'+item.level">{{ item.content }}</p></ng-container>
    <ng-container *ngSwitchCase="'inline'">
      <app-inline-render [content]="item.content"></app-inline-render>
    </ng-container>
    <ng-container *ngSwitchCase="'list'">
      <ul>
        <li *ngFor="let li of item.content">
          <app-inline-render [content]="li.content"></app-inline-render>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="'table'">
      <app-table [table]="item"></app-table>
    </ng-container>
    <ng-container *ngSwitchCase="'hr'">
      <hr/>
    </ng-container>
    <ng-container *ngSwitchDefault>{{ item.content }}</ng-container>
  </ng-container>
</ng-container>
