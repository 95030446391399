<mat-toolbar [color]="'primary'">
  <span [routerLink]="'dashboard'" style="cursor: pointer;">Simulacra</span>
  <button mat-button [routerLink]="'ancestry-list'">Ancestries</button>
  <button mat-button [routerLink]="'creature-list'">Creatures</button>
  <button mat-button [routerLink]="'item-list'">Items</button>
  <button mat-button [routerLink]="'path-list'">Paths</button>
  <button mat-button [routerLink]="'relic-list'">Relics</button>
  <button mat-button [routerLink]="'spell-list'">Spells</button>
  <button mat-button [routerLink]="'tradition-list'">Traditions</button>
  <button mat-button [routerLink]="'vehicle-list'">Vehicles</button>
  <button mat-button [matMenuTriggerFor]="refMenu">References</button>
  <mat-menu #refMenu="matMenu">
    <button mat-menu-item [routerLink]="'reference-list/traditiontalents'">Tradition Talents</button>
  </mat-menu>
  <span class="spacer"></span>
  <app-global-search></app-global-search>
  <button mat-icon-button [routerLink]="'contents'">
    <mat-icon>settings</mat-icon>
  </button>
</mat-toolbar>
<div style="padding:20px;">
  <router-outlet></router-outlet>
</div>
