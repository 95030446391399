<h2 mat-dialog-title>Reference : {{reference.name}}
  <button mat-button (click)="openPreview()">Preview</button>
</h2>
<mat-dialog-content>
  <mat-card>
    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="reference.name" (ngModelChange)="applyId()" placeholder="Name"/>
      </mat-form-field>
      <app-id-edit [value]="reference.id" (valueChange)="reference.id = $event"></app-id-edit>
    </div>
    <div class="flex">
      <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select [(value)]="reference.category">
            <mat-option [value]="'TraditionTalents'">Tradition Talents</mat-option>
            <mat-option [value]="'Other'">Other</mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    <div class="flex">
      <app-md-input [label]="'Content'" [name]="'content'" [id]="'content'"
                    style="width: 100%"
                    [(model)]="reference.content"></app-md-input>
    </div>
    <app-source-input [source]="reference.source"></app-source-input>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
