<h2 mat-dialog-title>Relic : {{relic.name}}
  <button mat-button (click)="openPreview()">Preview</button>
</h2>
<mat-dialog-content>
  <mat-card>
    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="relic.name" (ngModelChange)="applyId()" placeholder="Name"/>
      </mat-form-field>
      <app-id-edit [value]="relic.id" (valueChange)="relic.id = $event"></app-id-edit>
    </div>
    <app-ability-list-input [(list)]="relic.gameEffects" [title]="'Game Effects'"></app-ability-list-input>

    <app-md-input [label]="'Description'" [name]="'description'" [id]="'description'"
                  [(model)]="relic.description"></app-md-input>
    <app-source-input [source]="relic.source"></app-source-input>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
