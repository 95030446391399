<div style="display: flex; flex-direction: row;">
  <div class="table-side">
    <app-ancestry-filter (filterChange)="applyFilter($event)" [sources]="bookSources"></app-ancestry-filter>
    <div style="height: 400px; overflow: auto;">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
          <td mat-cell *matCellDef="let element">{{ element.source.book }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" (click)="select(myRowData)"
            class="selected" [class.selected]="myRowData.id === selected?.id"></tr>
      </table>
    </div>
  </div>
  <div class="details-side" *ngIf="selected">
    <app-ancestry-details [ancestry]="selected"></app-ancestry-details>
  </div>
</div>
