<h2 mat-dialog-title>Item : {{item.name}}
  <button mat-button (click)="openPreview()">Preview</button>
</h2>
<mat-dialog-content>
  <mat-card>
    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="item.name" (ngModelChange)="applyId()" placeholder="Name"/>
      </mat-form-field>
      <app-id-edit [value]="item.id" (valueChange)="item.id = $event"></app-id-edit>
    </div>

    <div class="flex">
      <mat-form-field>
        <mat-label>Availability</mat-label>
        <mat-select [(value)]="item.availability">
          <mat-option [value]="'Common'">Common</mat-option>
          <mat-option [value]="'Uncommon'">Uncommon</mat-option>
          <mat-option [value]="'Rare'">Rare</mat-option>
          <mat-option [value]="'Exotic'">Exotic</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="number" [(ngModel)]="item.price" placeholder="Price"/>
      </mat-form-field>

      <mat-form-field>
        <input matInput [(ngModel)]="item.type" placeholder="Type"/>
      </mat-form-field>
    </div>
    <app-md-input [label]="'Description'" [name]="'description'" [id]="'description'"
                  [(model)]="item.description"></app-md-input>
    <app-source-input [source]="item.source"></app-source-input>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
