<div *ngIf="magic">
  <mat-form-field>
    <input matInput type="number" [(ngModel)]="magic.power" placeholder="Power" (change)="magicChange.emit(magic)"/>
  </mat-form-field>
  <label> Traditions
    <button mat-mini-fab (click)="addTradition()">
      <mat-icon>add</mat-icon>
    </button>
  </label>
  <div *ngFor="let tradition of magic.traditions; let i = index; trackBy: trackByFn">
    <mat-form-field>
      <input matInput [(ngModel)]="tradition.tradition" [placeholder]="'Name'" [name]="'tradition_name_' + i" (change)="magicChange.emit(magic)"/>
      <mat-icon matSuffix (click)="removeTradition(i)">remove</mat-icon>
    </mat-form-field>
    <app-string-list-input [(list)]="tradition.spells" (listChange)="magicChange.emit(magic)"
                           [title]="tradition.tradition + ' Spells'"></app-string-list-input>
  </div>
</div>
