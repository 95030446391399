<h3>Source</h3>
<div class="flex">
  <mat-form-field>
    <input matInput [(ngModel)]="source.book" placeholder="Book"/>
  </mat-form-field>
  <mat-form-field>
    <input matInput type="number" [(ngModel)]="source.page"
           placeholder="Book (Page)"/>
  </mat-form-field>
</div>
