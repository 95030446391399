<h2 mat-dialog-title>Creature : {{creature.name}}
  <button mat-button (click)="openPreview()">Preview</button>
</h2>
<mat-dialog-content>
  <mat-card>
    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="creature.name" (ngModelChange)="applyId()" placeholder="Name"/>
      </mat-form-field>
      <app-id-edit [value]="creature.id" (valueChange)="creature.id = $event"></app-id-edit>
      <mat-form-field>
        <mat-label>Difficulty</mat-label>
        <mat-select [(value)]="creature.difficulty">
          <mat-option [value]="1">1</mat-option>
          <mat-option [value]="5">5</mat-option>
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="25">25</mat-option>
          <mat-option [value]="50">50</mat-option>
          <mat-option [value]="100">100</mat-option>
          <mat-option [value]="250">250</mat-option>
          <mat-option [value]="500">500</mat-option>
          <mat-option [value]="750">750</mat-option>
          <mat-option [value]="1000">1000</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="creature.size" placeholder="Size"/>
      </mat-form-field>
    </div>

    <div class="flex">
      <mat-form-field>
        <mat-label>Frightening / Horrifying:</mat-label>
        <mat-select [(value)]="creature.frightening">
          <mat-option value=""></mat-option>
          <mat-option value="frightening">frightening</mat-option>
          <mat-option value="horrifying">horrifying</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="creature.descriptor"
               placeholder="Descriptor"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number"
               [(ngModel)]="creature.perception"
               placeholder="Perception"/>
      </mat-form-field>

      <mat-form-field>
        <input matInput [(ngModel)]="creature.specialSenses"
               placeholder="Special Senses"/>
      </mat-form-field>
    </div>

    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="creature.defense"
               placeholder="Defense"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="creature.armor" placeholder="Armor"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="creature.health"
               placeholder="Health"/>
      </mat-form-field>
    </div>

    <div class="flex">
      <mat-form-field>
        <input matInput type="number"
               [(ngModel)]="creature.strength"
               placeholder="Strength"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" [(ngModel)]="creature.agility"
               placeholder="Agility"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" [(ngModel)]="creature.intellect"
               placeholder="Intellect"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" [(ngModel)]="creature.will"
               placeholder="Will"/>
      </mat-form-field>
    </div>
    <div class="flex">
      <mat-form-field>
        <input matInput [(ngModel)]="creature.insanity"
               placeholder="Insanity"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="creature.corruption"
               placeholder="Corruption"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="creature.speed"
               placeholder="Speed"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="creature.specialMovement"
               placeholder="Special Movement"/>
      </mat-form-field>
    </div>

    <app-ability-list-input [(list)]="creature.traits" [title]="'Traits'">
    </app-ability-list-input>

    <app-ability-list-input [(list)]="creature.attacks" [title]="'Attacks'">
    </app-ability-list-input>
    <app-ability-list-input [(list)]="creature.specialAttacks" [title]="'Special Attacks'">
    </app-ability-list-input>
    <app-ability-list-input [(list)]="creature.specialActions" [title]="'Special Actions'">
    </app-ability-list-input>

    <mat-checkbox [(ngModel)]="usesMagic" (change)="changeMagic()">
      Magic
    </mat-checkbox>
    <app-creature-magic-input [(magic)]="creature.magic"></app-creature-magic-input>

    <app-ability-list-input [(list)]="creature.endOfRound" [title]="'End of Round'">
    </app-ability-list-input>

    <app-md-input [label]="'Description'" [name]="'description'" [id]="'description'"
                  [(model)]="creature.description"></app-md-input>
    <app-md-input [label]="'Extra'" [name]="'extra'" [id]="'extra'" [(model)]="creature.extra"></app-md-input>
    <app-source-input [source]="creature.source"></app-source-input>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
