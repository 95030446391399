<footer>
  <mat-divider></mat-divider>
  <div class="content">
    <div>Current version: {{ version }}</div>
    <div>Contact for bug reports / ideas at <a href="mailto:smlcr.app@gmail.com">smlcr.app@gmail.com</a></div>
    <div>Follow the development at <a href="https://trello.com/b/IIfn1sUz/simulacra">Trello</a> or <a
      href="https://github.com/roonel/simulacra">Github</a></div>
    <div><a mat-button [routerLink]="'/license'">License information</a></div>
    <div>
      <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
        <img alt="Creative Commons License" style="border-width:0"
             src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png"/>
      </a>
    </div>
    <div>
      <link href="https://fonts.googleapis.com/css?family=Quicksand:400,700" rel="stylesheet" type="text/css">
      <div class="btn-container">
        <a title="Support me on ko-fi.com" class="kofi-button" href='https://ko-fi.com/R6R63L263'
           style="background-color: rgb(194, 24, 91); cursor: default;" target="_blank">
        <span class="kofitext">
        <img src="https://storage.ko-fi.com/cdn/cup-border.png" alt="Ko-fi donations" class="kofiimg">Support the development</span></a>
      </div>
    </div>
  </div>
</footer>
