<mat-card>
  <mat-card-title>
    {{ item.name }}
  </mat-card-title>
  <mat-card-subtitle>
    {{item.type}}
  </mat-card-subtitle>
  <mat-card-content>
    <span *ngIf="item.description">
      <app-processed-text [text]="item.description"></app-processed-text>
    </span>
  </mat-card-content>
  <mat-card-footer>
    {{ item.source.book }} Page {{ item.source.page }}
    <span style="float: right">
      {{item.price | price}} - {{item.availability}}
    </span>
  </mat-card-footer>
</mat-card>
